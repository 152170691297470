

import Starter from "@/modules/payments/components/Starter.vue";
import GeneralData from "@/modules/payments/components/GeneralData.vue";
import Payables from "@/modules/payments/components/Payables.vue";
import Depositable from "@/modules/payments/components/Depositable.vue";
import Methods from "@/modules/payments/components/Methods.vue";
import Loading from "@/components/Loading.vue";
import usePayment from "@/modules/payments/composables/usePayment";
import {onMounted, ref} from "vue";
import router from "@/router";
import toastr from "toastr";

export default {
    components: {Starter, GeneralData, Payables, Depositable, Methods, Loading},
    setup() {
        const {clearForm, paymentForm, createPayment, catalogs} = usePayment()
        const sending = ref(false)
        const loading = ref(true)

        clearForm();
        onMounted(async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const starterType = urlParams.get('starterType');
            const starterId = urlParams.get('starterId');

            if (starterType) {
                paymentForm.value.starterType = starterType
                paymentForm.value.starterId = starterId || ''
            }

            loading.value = false
        })

        return {
            mode: 'CREATE',
            sending,
            loading,
            catalogs,
            paymentForm,
            cancel: () => router.push({name: 'payments'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createPayment(paymentForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'payments'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
